import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/layout'
import PrimaryBannerVideo from '../components/primary-banner/primary-banner';
import SolutionsBlock from '../components/solutions-block/solutions-block';
import BrandLogos from '../components/brand-logos/brand-logos';
// import MulticolumnBanners from '../components/multicolumn-banners/multicolumn-banners';
import OverlayBlock from '../components/overlay-block/overlay-block';

import Seo from '../components/seo';

class RootIndex extends React.Component {
  render() {
    const homePageTopBanner = get(this, 'props.data.homePageTopBanner')
    const homePageOverlayBlock = get(this, 'props.data.homePageOverlayBlock')
    const homePageSolutionsBlock = get(this, 'props.data.homePageSolutionsBlock')
    const homePageBrandLogos = get(this, 'props.data.homePageSolutionsBlock')
    const homePageMulticolumnHeading = get(this, 'props.data.homePageMulticolumnHeading')

    return (
      <Layout location={this.props.location}>
        <Seo title="Home"/>
          <div className="container-fluid container-fluid--top">
            <PrimaryBannerVideo
              video={homePageTopBanner.video ? homePageTopBanner.video.url : null}
              headingText={homePageTopBanner.title}
              image={homePageTopBanner.backgroundImage.url}
              buttonPrimaryUrl={homePageTopBanner.primaryButtonUrl}
              buttonPrimaryText={homePageTopBanner.primaryButtonText}
              buttonSecondaryUrl={homePageTopBanner.secondaryButtonUrl}
              buttonSecondaryText={homePageTopBanner.secondaryButtonText}
            />
            <OverlayBlock introText={homePageOverlayBlock.subtitle} headingText={homePageOverlayBlock.title} buttonUrl={homePageOverlayBlock.buttonUrl} buttonText={homePageOverlayBlock.buttonText} image={homePageOverlayBlock.backgroundImage.url} descriptionOneText={homePageOverlayBlock.descriptionOneText} descriptionTwoText={homePageOverlayBlock.descriptionTwoText} />
            {/* <MulticolumnBanners banners={data.homePageMulticolumnBanner.nodes} /> */}
            <SolutionsBlock solutions={homePageSolutionsBlock} />
            <BrandLogos heading={homePageMulticolumnHeading} logos={homePageBrandLogos.nodes} />
          </div>
      </Layout>
    )
  }
}

export default RootIndex

// https://app.contentful.com/spaces/7upl5dktefra/content_types/primaryBanner/fields - Primary Banner Field Schema

export const query = graphql`
query MyQuery {
  homePageTopBanner: contentfulPrimaryBanner(primaryBannerName: {eq: "homePageTopBanner"}) {
    title
    primaryBannerName
    primaryButtonUrl
    primaryButtonText
    secondaryButtonUrl
    secondaryButtonText
    backgroundImage {
			url
    }
  }
  homePageOverlayBlock: contentfulOverlayBlock(overlayBlockName: {eq: "homePageOverlayBlock"}) {
    overlayBlockName
    subtitle
    title
    descriptionOneText
    descriptionTwoText
    buttonUrl
    buttonText
    backgroundImage {
			url
    }
  },
  homePageMulticolumnBanner: allContentfulMulticolumnBanner {
    nodes {
      title
      image {
        url
      }
      icon {
        url
      }
    }
  }
  homePageSolutionsBlock: contentfulSolutionsBlock(solutionsBlockName: {eq: "homePageSolutionsBlock"}) {
    headingTitle
    headingSubtitle
    titleOne
    descriptionOne
    iconOne {
      url
    }
    titleTwo
    descriptionTwo
    iconTwo {
      url
    }
    titleThree
    descriptionThree
    iconThree {
      url
    }
  }
  homePageMulticolumnHeading: contentfulMulticolumnHeading (multicolumnHeadingName: {eq: "homePageMulticolumnHeading"}) {
    titleText
  }
  homePageBrandLogos: allContentfulBrandLogos {
    nodes {
      siteLink
      image {
        url
      }
    }
  }
}
`
